<template>
  <div class="view">
    <div
      class="listDetail"
      :class="{ listDetailMove: store.state.lsitDetailStatus }"
    >
      <listDetail ></listDetail>
    </div>
    <div class="recomList">
      <h1>推荐歌单</h1>
      <ul>
        <li
          v-for="(list, index) in data.recommendLists"
          :key="index"
          @click="OpenListDetail(index, 0)"
        >
          <img class="re-img" :src="`${list.coverImgUrl}`" alt="" />
          <i>{{ list.name }}</i>
        </li>
      </ul>
    </div>
    <div class="toplist">
      <h1>排行榜</h1>
      <ul>
        <li
          v-for="(list, index) in data.topLists"
          :key="index"
          @click="OpenListDetail(index, 1)"
        >
          <img class="top-img" :src="`${list.coverImgUrl}`" alt="" />
          <i>{{ list.name }}</i>
        </li>
      </ul>
    </div>
  </div>
</template>
<script setup>
import { recommendList, getTopList } from "@/request/getInfor";
import listDetail from "./listDetail.vue";
import { onMounted, reactive } from "vue";
import bus from "@/Bus/bus";
import store from "@/store";
const data = reactive({
  recommendLists: [],
  topLists: [],
});
//点击传送到listdetail可以去到详情
const OpenListDetail = (index, num) => {
  num == 0
    ? bus.emit("OpenListDetail", data.recommendLists[index])
    : bus.emit("OpenListDetail", data.topLists[index]);
    store.commit("changelistDetailStatus");
};
onMounted(() => {
  if (!localStorage.getItem("recommendLists")) {
    //推荐歌单部分
    recommendList()
      .then((res) => {
        console.log(res);
        data.recommendLists = res.data.recommend.map((list) => ({
          nickname: list.creator.nickname,
          trackCount: list.trackCount,
          coverImgUrl: list.picUrl,
          name: list.name,
          id: list.id,
          avatarUrl: list.creator.avatarUrl,
        }));
        console.log(res);
      })
      .finally(() => {
        localStorage.setItem(
          "recommendLists",
          JSON.stringify(data.recommendLists)
        );
      });
  } else {
    data.recommendLists = JSON.parse(localStorage.getItem("recommendLists"));
  }
  //先用getTopList获取排行榜再用playListDetail获取歌单详情，歌单格式和之前一样
  if (!localStorage.getItem("topLists")) {
    getTopList()
      .then((res) => {
        data.topLists = res.data.list.map((list) => ({
          nickname: null,
          trackCount: list.trackCount,
          coverImgUrl: list.coverImgUrl,
          name: list.name,
          id: list.id,
          avatarUrl: null,
        }));
      })
      .finally(() => {
        data.topLists = localStorage.setItem(
          "topLists",
          JSON.stringify(data.topLists)
        );
      });
  } else {
    data.topLists = JSON.parse(localStorage.getItem("topLists"));
  }
});
</script>
<style scoped lang='scss'>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  scrollbar-width: none;
  background-color: var(--background-color);
  color: var(--text-color);
}
.listDetail {
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  transition: 0.5s;
  transform: translateX(100vh);
}
.listDetailMove {
  transform: translateX(0);
  transition: 0.5s;
}
.view {
  width: 100vw;
  height: 78vh;
}
.recomList {
  h1 {
    font-size: 20px;
    padding: 5px 20px 5px;
  }
  ul {
    padding: 0 15px 5px 15px;
    width: 100vw;
    display: flex;
    overflow-x: auto;
    li {
      width: 30vw;
      display: flex;
      flex-direction: column;
      text-align: center;
      margin: 0 5px 0;
      .re-img {
        margin: 0 auto;
        width: 28vw;
        border-radius: 10px;
      }
      i {
        font-size: 12px;
      }
    }
  }
}
.toplist {
  h1 {
    font-size: 20px;
    padding: 5px 20px 5px;
  }
  ul {
    width: 100vw;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 0 15px 5px;
    li {
      width: 30vw;
      height: 40vw;
      display: inline-block;
      text-align: center;
      overflow: hidden;
      .top-img {
        display: block;
        margin: 0 auto;
        width: 28vw;
        border-radius: 10px;
      }
      i {
        font-size: 12px;
      }
    }
  }
}
</style>
