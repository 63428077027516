<template>
  <div class="set">
    <div class="head">
      <img class="avatar" :src="`${data.userdata.avatarUrl}`" alt="" /><i
        class="username"
        >{{ data.userdata.nickname }}</i
      >
      <i class="iconfont icon-jinru"></i>
    </div>
    <div class="main">
      <li class="li1">
        <i class="iconfont icon-shensemoshi"></i><i class="text">深色模式</i>
        <el-switch
          v-model="store.state.darkmode"
          size="small"
          style="--el-switch-on-color: #fd2420"
          class="switch"
        />
      </li>
      <li class="li1" @click="NavigateToAboutView">
        <i class="iconfont icon-guanyu"></i><i class="text">关于</i
        ><i class="iconfont icon-jinru"></i>
      </li>
    </div>
    <div class="foot">
      <li @click="exitLogin">
        <i class="iconfont icon-tuichudenglu"></i><i class="text">退出登录</i
        ><i class="iconfont icon-jinru"></i>
      </li>
    </div>
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";
import { reactive } from "vue";
import { getDetail } from "@/request/getInfor";
import { logOut } from "@/request/login";
import { onMounted} from "vue";
import store from "@/store";
const data = reactive({
  uid: localStorage.getItem("userId"),
  userdata: {
    nickname: "",
    avatarUrl: "",
  },
});
const router = useRouter();
const exitLogin = () => {
  //退出登录
  logOut();
  router.push("/");
};
const getdetail = () => {
  getDetail(data).then((res) => {
    //获取信息
    data.userdata.nickname = res.data.profile.nickname;
    data.userdata.avatarUrl = res.data.profile.avatarUrl;
    localStorage.setItem("userdata", JSON.stringify(data.userdata));
  });
};
onMounted(() => {
  localStorage.getItem("userdata")
    ? (data.userdata = JSON.parse(localStorage.getItem("userdata")))
    : getdetail();
});
const NavigateToAboutView = () => {
  router.push("/About");
};
</script>
<style scoped lang='scss'>
.set {
  display: inline-block;
  width: 80vw;
  height: 100vh;
  background-color: var(--background-color);
  padding: 20px;
  .head {
    height: 10vh;
    color: var(--text-color);
    line-height: 10vh;

    .avatar {
      width: 50px;
      height: 50px;
      border-radius: 50px;
    }
    .username {
      margin-left: 15px;
    }
  }
  .main {
    height: 80vh;
    color: var(--text-color);
    .li1 {
      margin-top: 20px;
    }
  }
  .foot {
    height: 10vh;
    color: var(--text-color);
    .icon-tuichudenglu,
    .text {
      color: #fd2420;
    }
  }
  .icon-jinru,
  .switch {
    float: right;
    font-size: 15px;
    margin-right: 20px;
  }
  .text {
    margin-left: 20px;
  }
}
</style>
