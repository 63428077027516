<template>
  <div>
  <div
    class="view"
    v-if="store.state.currentMusicListStatus"
  >
    <div class="opacity" @click="currentMusicListStatus"></div>
    <div class="head">
      <i class="iconfont icon-hengtiao" @click="currentMusicListStatus"></i>
      <i class="text">当前播放</i>
    </div>
    <div class="main">
      <i @click="changeplayPattern">
      <i
        class="iconfont"
        :class="{
          'icon-shunxubofang': store.state.playPattern == 0,
          'icon-xunhuanbofang': store.state.playPattern == 1,
          'icon-suiji2': store.state.playPattern == 2,
        }"
        
      ></i
      ><span v-text="playPatternContent"></span></i>
    </div>
    <div class="foot">
      <ul v-for="(music, index) in store.state.currentPlayList" :key="index">
        <li :class="{ highLight: index == store.state.selectedMusic }">
          <span @click="selectedMusic(index)">
            <i>{{ music.name }}</i
            ><i class="iconfont icon--xiaoheidian"></i
            ><i>{{ music.singer.name }}</i>
          </span>
          <i class="iconfont icon-shanchu" @click="deleteMusic(index)"></i>
        </li>
      </ul>
    </div>
  </div>
</div>
</template>
<script setup>
import { computed} from "vue";
import store from "@/store";
const changeplayPattern = () => {
  store.state.playPattern == 2
    ? (store.state.playPattern = 0)
    : store.state.playPattern++;
};
const playPatternContent = computed(() => {
  switch (store.state.playPattern) {
    case 0:
      return "顺序";
    case 1:
      return "循环";
    case 2:
      return "随机";
    default:
      return "顺序";
  }
});
const deleteMusic = (index) => {
  store.state.currentPlayList.splice(index, 1);
  index > store.state.selectedMusic ? true : store.state.selectedMusic--;
};
const selectedMusic = (index) => {
  store.state.selectedMusic = index;
  store.state.musicStatus = false;
  setTimeout(() => {
    store.state.musicStatus = true;
  });
};
const currentMusicListStatus = () => {
  store.commit("changeCurrentMusicListStatus");
};
</script>
<style scoped lang='scss'>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  scrollbar-width: none;
}
.view {
  color: var(--text-color);
  height: 100vh;
}
.opacity {
  height: 30vh;
}
.head {
  height: 7vh;
  background-color: var(--background-color);
  .icon-hengtiao {
    display: block;
    width: 50px;
    height: 3vh;
    font-size: 5px;
    margin: 0 auto;
    padding: 5px;
  }
  .text {
    font-size: 15px;
    margin-left: 20px;
    padding-bottom: 3px;
    border: 3px solid #ffffff;
    border-left: none;
    border-top: none;
    border-right: none;
    line-height: 4vh;
  }
}
.main {
  height: 5vh;
  background-color: var(--background-color);
  .iconfont {
    margin-left: 20px;
    line-height: 5vh;
  }
  span {
    margin-left: 5px;
  }
}
.foot {
  height: 58vh;
  background-color: var(--background-color);
  overflow-y: auto;
  ul {
    li {
      height: 40px; 
      overflow: hidden;
      position: relative;
      span{
        display: inline-block;
        position: a;
        width: 90vw;
        z-index: 1;
        overflow: hidden;
      }
      i {
        margin-left: 10px;
        line-height: 40px;
      }
      i:nth-child(1) {
        margin-left: 20px;
      }
      .icon-shanchu {
        position: absolute;
        z-index: 2;
        right: 0;
        margin-right: 10px;
      }
    }
  }
}
.highLight {
  background-color: var(--background-color);
  color: #fd2420;
}
</style>
