<template>
  <div>
    <div
      class="player"
      v-if="store.state.currentPlayList && store.state.currentPlayList.length"
    >
      <div class="left" @click="NavigateToMusicPlayer">
        <img
          class="playImg"
          :src="store.state.currentPlayList[store.state.selectedMusic].picUrl"
          alt=""
        />
      </div>
      <div
        class="main"
        @click="NavigateToMusicPlayer"
        @touchstart="handleStart"
        @touchmove="handleMove"
        @touchend="handleEnd"
      >
        <i>{{ store.state.currentPlayList[store.state.selectedMusic].name }}</i
        >-
        <i>{{
          store.state.currentPlayList[store.state.selectedMusic].singer.name
        }}</i>
      </div>
      <div class="right">
        <i
          class="iconfont"
          :class="{
            'icon-bofang1': !store.state.musicStatus,
            'icon-bofang': store.state.musicStatus,
          }"
          @click="changeMusicStatus"
        ></i>
        <i
          class="iconfont icon-zhankai"
          @click="changeCurrentMusicListStatus"
        ></i>
      </div>
    </div>
  </div>
</template>
<script setup>
import store from "@/store";
import { onMounted, ref} from "vue";
import bus from "@/Bus/bus";
const startX = ref(0);
const startY = ref(0);
const endX = ref(0);
const endY = ref(0);
const handleStart = (event) => {
  startX.value = event.touches[0].clientX;
  startY.value = event.touches[0].clientY;
};
const handleMove = (event) => {
  endX.value = event.touches[0].clientX;
  endY.value = event.touches[0].clientY;
};
const handleEnd = () => {
  const diffX = endX.value - startX.value;
  const diffY = endY.value - startY.value;
  if (Math.abs(diffX) > Math.abs(diffY)) {
    if (diffX > 30) {
      bus.emit("changemusic", 0);
    } else {
      bus.emit("changemusic", 1);
    }
    startX.value = 0;
    startY.value = 0;
    endX.value = 0;
    endY.value = 0;
  }
};
const NavigateToMusicPlayer = () => {
  store.commit("changeMusicPlayerStatus");
};
const changeMusicStatus = () => {
  store.commit("changeMusicStatus");
};
const changeCurrentMusicListStatus = () => {
  //展开歌单
  store.commit("changeCurrentMusicListStatus");
};
onMounted(() => {
  store.state.currentPlayList = JSON.parse(
    localStorage.getItem("currentPlayList")
  );
});
</script>
<style scoped lang='scss'>
.player {
  width: 100vw;
  height: 6vh;
  background-color: var(--background-color);
  display: flex;
  .left {
    width: 15vw;
    text-align: center;
    align-content: center;
    .playImg {
      width: 5vh;
      height: 5vh;
      border: 7px solid black;
      border-radius: 5vh;
    }
  }
  .main {
    width: 60vw;
    position: relative;
    z-index: 1;
    overflow: hidden;
    i {
      font-size: 17px;
      line-height: 6vh;
      color: var(--text-color);
    }
  }
  .right {
    width: 25vw;
    display: flex;
    justify-content: space-evenly;
    position: relative;
    z-index: 2;
    .iconfont {
      font-size: 30px;
      line-height: 6vh;
      color: var(--text-color);
    }
  }
}
</style>
