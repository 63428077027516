<template>
  <div class="main">
    <ul v-if="data.lrcArray.length > 0 && data.tlyricArray.length > 0">
      <li
        v-for="(lyric, index) in data.lrcArray"
        :key="index"
        :style="{ transform: `translateY(${-data.currentLyricIndex * 65}px)` }"
        :class="{ currentLyric: index == data.currentLyricIndex }"
      >
        <i>{{ lyric.text }}</i>
        <i
          v-if="
            data.tlyricArray[
              index - (data.lrcArray.length - data.tlyricArray.length)
            ]
          "
          >{{
            data.tlyricArray[
              index - (data.lrcArray.length - data.tlyricArray.length)
            ].text
          }}</i
        >
      </li>
    </ul>
    <i class="tips" v-if="data.lrcArray.length ==0 && data.tlyricArray.length ==0">暂无歌词</i>
  </div>
</template>
<script setup>
import { getlyric } from "@/request/getInfor";
import { onMounted, reactive, watch } from "vue";
import { throttle } from "lodash";
import store from "@/store";
// import lyric from ".././歌词.json";
const data = reactive({
  lrcArray: [],
  tlyricArray: [],
  currentLyricIndex: null,
});
const parseTime = (timeString) => {
  //将时间戳转化为秒数
  const parts = timeString.split(":");
  const minutes = parseInt(parts[0], 10);
  const seconds = parseFloat(parts[1]);
  return minutes * 60 + seconds;
};
const parseLyrics = (lyric) => {
  //切割歌词
  const lines = lyric.split("\n");
  const result = [];
  const timeRegExp = /\[(\d{2}:\d{2}\.\d{3})\](.*)/;
  lines.forEach((line) => {
    const match = timeRegExp.exec(line);
    if (match) {
      const time = parseTime(match[1]);
      const text = match[2].trim();
      if (text) {
        result.push({ time, text });
      }
    }
  });
  return result;
};
const updateLyrics = throttle((currentTime, lyricArray) => {
  // 遍历歌词数组
  for (let i = 0; i < lyricArray.length; i++) {
    if (currentTime < lyricArray[i].time) {
      data.currentLyricIndex = i - 1;
      return 0;
    }
  }
  data.currentLyricIndex = lyricArray.length - 1;
  return 0;
}, 500);
const getLyrics = () => {
  getlyric(store.state.currentPlayList[store.state.selectedMusic]).then(
    (res) => {
      if (res.data.lrc.lyric) {
        data.lrcArray = parseLyrics(res.data.lrc.lyric);
        // localStorage.setItem('lrcArray',data.lrcArray)
      }
      if (res.data.tlyric.lyric) {
        data.tlyricArray = parseLyrics(res.data.tlyric.lyric);
        // localStorage.setItem('tlyricArray',data.tlyricArray)
      }
    }
  );
};
watch(
  () => store.state.currentTime,
  (newval) => {
    updateLyrics(newval, data.lrcArray);
  }
);

watch(
  () => store.state.currentPlayList[store.state.selectedMusic].id,
  () => getLyrics()
);
onMounted(() => {
   getLyrics();
  // localStorage.getItem('lrcArray')?data.lrcArray=localStorage.getItem('lrcArray'):getLyrics()
  // localStorage.getItem('tlyricArray')?data.tlyricArray=localStorage.getItem('tlyricArray'):getLyrics()
});
</script>
<style scoped lang='scss'>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}
.tips{
  line-height: 64vh;
}
.main {
  height: 64vh;
  width: 100vw;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  backdrop-filter: blur(10px);
  mask-image: linear-gradient(
    to bottom,
    transparent,
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 1),
    transparent
  );

  ul {
    width: 100vw;
    margin-top: 32vh;
    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100vw;
      height: 65px;
      transition: 0.1s;
      i{
        color: var(--text-color);
      }
    }
  }
}
.currentLyric {
  color: red;
}
</style>
