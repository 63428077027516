<template>
  <div class="view">
    <div class="head">
      <i class="iconfont icon-fanhui2" @click="changeSearchMusicStatus"></i>
      <input class="input" type="text" v-model="data.keywords" />
      <i class="text" @click="searchMusic">搜索</i>
    </div>
    <div class="main" v-if="data.searchMusicList.length != 0">
      <ul>
        <li v-for="(song, index) in data.searchMusicList" :key="index">
          <i class="text" @click="addCurrentList(index, 0)"
            ><i>{{ song.name }}</i
            ><i>{{ song.singer.name }}-{{ song.singer.albumName }}</i></i
          >
          <i
            class="iconfont icon-jiarugedan"
            @click="addCurrentList(index, 1)"
          ></i>
        </li>
      </ul>
    </div>
    <div class="foot">
      <HomePlayer></HomePlayer>
    </div>
  </div>
</template>
<script setup>
import { reactive } from "vue";
import HomePlayer from "./HomePlayer.vue";
import store from "@/store";
import { search, songDetail } from "@/request/getInfor";
const changeSearchMusicStatus = () => {
  store.commit("changeSearchMusicStatus");
};
const data = reactive({
  keywords: "",
  searchMusicList: [],
  selectedMusic: {},
});
const searchMusic = () => {
  search(data).then((res) => {
    data.searchMusicList = [];
    data.searchMusicList = res.data.result.songs.map((song) => ({
      id: song.id,
      name: song.name,
      singer: {
        name: song.artists[0].name,
        albumName: song.album.name,
      },
    }));
  });
};
const addCurrentList = (index, num) => {
  num == 0 ? true : (num += store.state.selectedMusic);
  songDetail(data.searchMusicList[index])
    .then((res) => {
      store.state.currentPlayList.splice(num, 0, {
        name: res.data.songs[0].name,
        id: res.data.songs[0].id,
        picUrl: res.data.songs[0].al.picUrl,
        totalTime: res.data.songs[0].dt,
        singer: {
          id: res.data.songs[0].ar[0].id,
          name: res.data.songs[0].ar[0].name,
        },
      });
    })
    .finally(() => {
      if (num == 0) {
        store.state.selectedMusic = 0;
        store.state.musicPlayerStatus = true;
        store.state.musicStatus = true;
      }
    });
};
</script>
<style scoped lang='scss'>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  background-color: var(--background-color);
  color: var(--text-color);
}
.view {
  height: 100vh;
}
.head {
  width: 100vw;
  height: 7vh;
  display: flex;
  justify-content: space-evenly;
  align-content: center;
  position: fixed;
  top: 0;
  z-index: 3;
  .iconfont {
    line-height: 7vh;
  }
  .icon-fanhui2 {
    font-size: 25px;
  }
  .text {
    font-size: 15px;
    line-height: 7vh;
  }
  .input {
    width: 65vw;
    height: 3.5vh;
    transform: translateY(50%);
    border: 1px solid #696969;
    border-radius: 3.5vh;
    background-color: #fff;
    opacity: 0.3;
  }
}
.main {
  height: 87vh;
  position: absolute;
  z-index: 1;
  top: 7vh;
  overflow-y: auto;
  ul {
    display: block;
    height: auto;
    li {
      height: 60px;
      width: 100vw;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .text {
        display: inline-block;
        width: 80vw;
        padding-left: 20px;
        i {
          display: block;
        }
        i:nth-child(1) {
          font-size: 15px;
        }
        i:nth-child(2) {
          font-size: 12px;
        }
      }
      i {
        margin: auto 0;
      }
      .icon-jiarugedan {
        float: right;
        font-size: 40px;
        padding-right: 20px;
      }
    }
  }
}

.foot {
  position: fixed;
  height: 6vh;
  bottom: 0;
  z-index: 3;
}
</style>