<template>
  <div>
    <div class="setting" :class="{ settingMove: data.settingstatus }">
      <settIng></settIng>
      <div class="right" @click="settingMove"></div>
    </div>
    <div
      class="musicPlayer"
      :class="{ musicPlayerMove: store.state.musicPlayerStatus }"
    >
      <musicPlayer></musicPlayer>
    </div>
    <div
      class="currentMusicList"
      :class="{ currentMusicListMove: store.state.currentMusicListStatus }"
    >
      <currentMusicList></currentMusicList>
    </div>
    <div class="searchMusic" :class="{ 'searchMusicMove': store.state.SearchMusicStatus }">
      <searchMusic></searchMusic>
    </div>
    <div class="fuzzyBgbefore" :class="{ fuzzyBgafter: data.settingstatus||store.state.currentMusicListStatus }"></div>
      <div class="head">
        <i class="iconfont icon-tuozhan" @click="settingMove"></i>
        <input class="input" type="text" @click="changeSearchMusicStatus" />
        <i class="iconfont icon-luyin"></i>
      </div>
      <div class="main">
        <router-view></router-view>
      </div>
      <div class="foot">
        <!-- 预留用于放置播放栏 -->
        <div class="player">
          <HomePlayer></HomePlayer>
        </div>
        <div class="navbar">
          <router-link class="router-link-active" to="/HomeMain/reCommend"
            ><i
              class="iconfont icon-wangyiyun1"
              :class="{ changecolor: data.changeColorIndex === 0 }"
              @click="changecolor(0)"
            ></i
            ><span>推荐</span></router-link
          >
          <router-link class="router-link-active" to="/HomeMain/playList"
            ><i
              class="iconfont icon-gedan"
              :class="{ changecolor: data.changeColorIndex === 1 }"
              @click="changecolor(1)"
            ></i
            ><span>歌单</span></router-link
          >
        </div>
      </div>
  </div>
</template>
<script setup>
import HomePlayer from "@/components/HomePlayer.vue";
import settIng from "@/components/settIng.vue";
import musicPlayer from "@/components/musicPlayer.vue";
import currentMusicList from "@/components/currentMusicList.vue";
import searchMusic from "@/components/searchMusic.vue";
import { reactive } from "vue";
import store from "@/store";
const data = reactive({
  changeColorIndex: 0,
  settingstatus: false,
});
const changeSearchMusicStatus = () => {
  store.commit("changeSearchMusicStatus");
};
const changecolor = (Index) => {
  data.changeColorIndex = Index;
};
const settingMove = () => {
  data.settingstatus = !data.settingstatus;
};
</script>
<style scoped lang='scss'>
.fuzzyBgbefore{
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;

}
.fuzzyBgafter {
  z-index: 4;
  background-color: #000000;
  opacity: 0.3;
  transition: 0.5s;
}
.setting {
  position: fixed;
  transform: translateX(-100vw);
  display: flex;
  width: 100vw;
  height: 100vh;
  z-index: 5;
  transition: 0.5s;
  .settIng {
    width: 80vw;
    height: 100vh;
  }
  .right {
    width: 20vw;
    height: 100vh;
  }
}

.settingMove {
  transform: translateX(0);
  transition: 0.5s;
}
.musicPlayer {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 4;
  transform: translateY(100vh);
  transition: 0.5s;
}
.musicPlayerMove {
  transform: translateY(0);
  transition: 0.5s;
}
.currentMusicList {
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 5;
  transform: translateY(100vh);
  transition: 0.5s;
}
.currentMusicListMove {
  transform: translateY(0);
  transition: 0.5s;
}
.searchMusic{
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 3;
  background-color: var(--background-color);
  transform: translateX(100vw);
  transition: 0.5s;
}
.searchMusicMove {
  transform: translateX(0);
  transition: 0.5s;
}
.head {
  width: 100vw;
  height: 7vh;
  position: fixed;
  background-color: var(--background-color);
  display: flex;
  justify-content: space-evenly;
  align-content: center;
  z-index: 2;
  .iconfont {
    line-height: 7vh;
    color: var(--text-color);
  }
  .icon-tuozhan {
    font-size: 25px;
  }
  .icon-luyin {
    font-size: 30px;
  }
  .input {
    width: 65vw;
    height: 3.5vh;
    transform: translateY(50%);
    border: 1px solid #696969;
    border-radius: 3.5vh;
    color: var(--text-color);
    opacity: 0.3;
  }
}
.main {
  position: absolute;
  top: 7vh;
  width: 100vw;
  height: 78vh;
  overflow-y: auto;
  scrollbar-width: none;
}
.foot {
  width: 100vw;
  height: 15vh;
position: fixed;
bottom: 0;
  .player {
    height: 6vh;
    background-color: var(--background-color);
  }
  .navbar {
    height: 9vh;
    background-color: var(--background-color);
    display: flex;
    justify-content: space-evenly;
    .router-link-active {
      width: 33vw;
      color: var(--text-color);
      text-decoration: none;
      text-align: center;
      font-size: 10px;
      .iconfont {
        display: block;
        font-size: 30px;
      }
    }
    .changecolor {
      color: #fd2420;
    }
  }
}
</style>
