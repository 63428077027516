<template>
  <div>
    <div class="view" v-if="store.state.lsitDetailStatus">
      <div class="head">
        <i
          ><span
            class="iconfont icon-fanhui2"
            @click="changelistDetailStatus"
          ></span
          ><span>歌单</span></i
        ><i class="iconfont icon-sousuo" @click="changeSearchMusicStatus"></i>
      </div>
      <div class="slide" ref="slideContainer" @scroll="handleScroll">
        <div class="main" v-if="data.list.name">
          <div class="content" >
            <img class="img-list" :src="`${data.list.coverImgUrl}`" alt="" /><i
              ><span>{{ data.list.name }}</span
              ><span v-if="data.list.nickname != null"
                ><img
                  class="img-avatar"
                  :src="`${data.list.avatarUrl}`"
                  alt=""
                /><span>{{ data.list.nickname }}</span></span
              ></i
            >
          </div>
        </div>
        <div class="foot">
          <div class="listCtl" :class="{ listCtlFixed: isFixed }">
            <i class="iconfont icon-bofang1" @click="changeCurrentList"
              ><span>全部播放</span></i
            >
          </div>
          <div class="listContent">
            <ul>
              <li v-for="(song, index) in data.playlist" :key="index" >
                <i class="text" @click="addCurrentList(index, 0)"
                  ><i
                    :class="{
                      currentMusic:
                        song.id ==
                        store.state.currentPlayList[store.state.selectedMusic]
                          .id,
                    }"
                    >{{ song.name }}</i
                  ><i>{{ song.singer.name }}</i></i
                >
                <i
                  class="iconfont icon-jiarugedan"
                  @click="addCurrentList(index, 1)"
                ></i>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="homeplay">
        <HomePlayer></HomePlayer>
      </div>
    </div>
  </div>
</template>

<script setup>
import store from "@/store";
import { reactive, ref } from "vue";
import bus from "@/Bus/bus";
import { playListDetail } from "@/request/getInfor";
import HomePlayer from "./HomePlayer.vue";
const data = reactive({
  list: {},
  playlist: [],
});

const isFixed = ref(false);
const slideContainer = ref(null);

const changeSearchMusicStatus = () => {
  store.commit("changeSearchMusicStatus");
};

const changelistDetailStatus = () => {
  store.commit("changelistDetailStatus");
  data.translate=0
};

const addCurrentList = (index, num) => {
  num === 0 ? true : (num += store.state.selectedMusic);
  store.state.currentPlayList.splice(num, 0, data.playlist[index]);
  setTimeout(() => {
    if (num === 0) {
      store.state.selectedMusic = 0;
      store.state.musicPlayerStatus = true;
      store.state.musicStatus = true;
    }
  });
};

const changeCurrentList = () => {
  store.state.currentPlayList = data.playlist;
  setTimeout(() => {
    store.state.musicStatus = true;
    store.commit("changeMusicPlayerStatus");
  });
};

const handleScroll = () => {
  if (slideContainer.value) {
    const threshold = 150;
    isFixed.value = slideContainer.value.scrollTop > threshold;
  }
};
bus.on("OpenListDetail", (list) => {
  //传入歌单，获取id后get歌单详情
  data.list = list;
  playListDetail(data.list).then((res) => {
    data.playlist = res.data.playlist.tracks.map((track) => ({
      name: track.name,
      id: track.id,
      picUrl: track.al.picUrl,
      totalTime: track.dt,
      singer: { id: track.ar[0].id, name: track.ar[0].name },
    }));
  });
});
</script>

<style scoped lang='scss'>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  scrollbar-width: none;
}
.view {
  background-color: var(--background-color);
  color: var(--text-color);
  .head {
    height: 7vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .iconfont {
      font-size: 25px;
      margin: auto 20px;
    }
    i {
      display: flex;
      align-items: center;
    }
  }
  .slide {
    overflow-y: auto;
  }
  .main {
    height: 20vh;
    display: flex;
    align-items: center;
    .content {
      height: 100px;
      display: flex;
      align-items: start;
      .img-list {
        width: 100px;
        height: 100px;
        margin: 0 10px 0 20px;
        border-radius: 10px;
      }
      i {
        display: flex;
        flex-direction: column;
        span:nth-child() {
          font-size: 15px;
        }
        span:nth-child(2) {
          margin-top: 10px;
          font-size: 12px;
          .img-avatar {
            width: 30px;
            height: 30px;
            border-radius: 30px;
            margin-right: 5px;
          }
        }
      }
    }
  }
  .foot {
    height: 67vh;
    .listCtl {
      width: 100vw;
      height: 40px;
      border-radius: 30px;
      padding-left: 20px;
      background-color: var(--background-color);
      i {
        font-size: 25px;
        display: flex;
        align-items: center;
        color: #fd2420;
        span {
          margin-left: 5px;
          color: var(--text-color);
          font-size: 15px;
        }
      }
    }
    .listCtlFixed {
      position: fixed;
      top: 7vh;
    }

    ul {
      display: block;
      height: auto;
      li {
        height: 60px;
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .text {
          display: inline-block;
          width: 80vw;
          padding-left: 20px;
          i {
            display: block;
          }
          i:nth-child(1) {
            font-size: 15px;
          }
          i:nth-child(2) {
            font-size: 12px;
          }
        }
        i {
          margin: auto 0;
        }
        .icon-jiarugedan {
          float: right;
          font-size: 40px;
          padding-right: 20px;
        }
      }
    }
  }
  .homeplay {
    position: fixed;
    height: 6vh;
    bottom: 0;
    z-index: 3;
  }
}

</style>
