<template>
  <div>
    <div
      class="total"
      v-if="store.state.currentPlayList && store.state.currentPlayList.length"
    >
      <div class="head">
        <i class="iconfont icon-xiala" @click="NavigateToHomeMain"></i>
      </div>
      <div class="main" @click="changemusicPlayerMainStatus">
        <musicImage v-if="!store.state.musicPlayerMainStatus"></musicImage>
        <lyricMusic v-if="store.state.musicPlayerMainStatus"></lyricMusic>
      </div>
      <div class="foot">
        <div class="infor">
          <li>
            <i>{{
              store.state.currentPlayList[store.state.selectedMusic].name
            }}</i>
          </li>
          <li>
            <i>{{
              store.state.currentPlayList[store.state.selectedMusic].singer.name
            }}</i>
          </li>
        </div>
        <div class="time">
          <el-slider
            class="range"
            v-model="store.state.currentTime"
            :max="
              store.state.currentPlayList[store.state.selectedMusic].totalTime /
              1000
            "
            :step="0.1"
            @input="seekToTime"
            :show-tooltip="false"
          />
          <audio
            ref="audioPlayer"
            :src="`https://music.163.com/song/media/outer/url?id=${
              store.state.currentPlayList[store.state.selectedMusic].id
            }.mp3`"
            @timeupdate="updateTime"
          ></audio>
          <ul>
            <li>{{ formatTime(store.state.currentTime) }}</li>
            <li>
              {{
                formatTime(
                  store.state.currentPlayList[store.state.selectedMusic]
                    .totalTime / 1000
                )
              }}
            </li>
          </ul>
        </div>
        <div class="button-foot">
          <ul>
            <li>
              <i
                class="iconfont"
                :class="{
                  'icon-shunxubofang': store.state.playPattern == 0,
                  'icon-xunhuanbofang': store.state.playPattern == 1,
                  'icon-suiji2': store.state.playPattern == 2,
                }"
                @click="changeplayPattern"
              ></i>
            </li>
            <li><i class="iconfont icon-pre" @click="preMusic"></i></li>
            <li>
              <i
                class="musicCtl iconfont"
                :class="{
                  'icon-jiediankaishi': !store.state.musicStatus,
                  'icon-zanting': store.state.musicStatus,
                }"
                @click="changeMusicStatus"
              ></i>
            </li>
            <li><i class="iconfont icon-next" @click="nextMusic"></i></li>
            <li>
              <i
                class="iconfont icon-leitituozhan"
                @click="changeCurrentMusicListStatus"
              ></i>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { reactive, ref, onMounted, watch } from "vue";
import store from "@/store";
import bus from "@/Bus/bus";
import musicImage from "./musicImage.vue";
import lyricMusic from "./lyricMusic.vue";
import { throttle } from "lodash";
const data = reactive({
  uid: localStorage.getItem("userId"),
  playPattern: 0,
  randomMusiclist: [],
});
const audioPlayer = ref(null);
//监听Homeplayer中的滑动切换音乐
bus.on("changemusic", (num) => {
  num == 0 ? preMusic() : nextMusic();
});

const changeMusicStatus = () => {
  //播放或暂停歌曲
  store.commit("changeMusicStatus");
};
const NavigateToHomeMain = () => {
  //回到主页面
  store.commit("changeMusicPlayerStatus");
};
const changemusicPlayerMainStatus = () => {
  store.commit("changemusicPlayerMainStatus");
};
//播放功能部分
const updateTime = () => {
  // 更新时间
  if (audioPlayer.value) {
    store.state.currentTime = audioPlayer.value.currentTime;
  }
};

const formatTime = (time) => {
  // 计算时间
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
    2,
    "0"
  )}`;
};
//移动滑块更新播放时间
const seekToTime = () => {
  audioPlayer.value.currentTime = store.state.currentTime;
};
const preMusic = throttle(() => {
  //上一曲
  store.state.selectedMusic == 0
    ? (store.state.selectedMusic = store.state.currentPlayList.length - 1)
    : store.state.selectedMusic--;
  store.state.musicStatus = true; //之后要改
  setTimeout(() => {
    audioPlayer.value.play();
  });
}, 500);
const nextMusic = throttle(() => {
  //下一曲
  store.state.selectedMusic == store.state.currentPlayList.length - 1
    ? (store.state.selectedMusic = 0)
    : store.state.selectedMusic++;
  store.state.musicStatus = true; //之后要改
  setTimeout(() => {
    audioPlayer.value.play();
  });
}, 500);
const changeplayPattern = () => {
  store.state.playPattern == 2
    ? (store.state.playPattern = 0)
    : store.state.playPattern++;
};
const loopMusic = () => {
  //循环播放
  audioPlayer.value.currentTime = 0;
  setTimeout(() => {
    audioPlayer.value.play();
  });
};

const randomMusic = (length) => {
  //随机播放
  if (data.randomMusiclist.length === 0) {
    data.randomMusiclist = Array.from({ length }, (_, index) => index);
  }
  const randomIndex = Math.floor(Math.random() * data.randomMusiclist.length);
  store.state.selectedMusic = data.randomMusiclist[randomIndex];
  data.randomMusiclist.splice(randomIndex, 1);
  setTimeout(() => {
    audioPlayer.value.play();
  });
};
watch(
  () => store.state.musicStatus,
  (musicStatus) => {
    //监视播放状态
    musicStatus ? audioPlayer.value.play() : audioPlayer.value.pause();
  }
);
const changeCurrentMusicListStatus = () => {
  //展开歌单
  store.commit("changeCurrentMusicListStatus");
};
onMounted(() => {
  if (audioPlayer.value) {
    //监听播放状态
    audioPlayer.value.addEventListener("ended", () => {
      switch (data.playPattern) {
        case 0:
          nextMusic(); //顺序播放
          break;
        case 1: //循环播放
          loopMusic();
          break;
        case 2:
          randomMusic(store.state.currentPlayList.length); //随机播放
          break;
      }
    });
  }
});
</script>
<style scoped lang='scss'>
* {
  margin: 0;
  padding: 0;
  color: var(--text-color);
}
.total {
  background-image: linear-gradient(to bottom, var(--music-color-top), var(--music-color-bottom));
  position: relative;
  z-index: 1;
  .head {
    height: 6vh;
    display: flex;
    justify-content: space-between;
    line-height: 6vh;
    padding: 0px 20px;
    position: relative;
    z-index: 1;
    .iconfont {
      font-size: 25px;
    }
  }
  .main {
    height: 64vh;
    position: relative;
    z-index: -1;
  }
  .foot {
    position: relative;
    z-index: 1;
    height: 30vh;
    .infor {
      height: 10vh;
      padding: 0 7.5vw;
      li:nth-child(1) {
        i {
          font-size: 15px;
        }
      }
      li:nth-child(2) {
        i {
          font-size: 12px;
        }
      }
    }

    .time {
      height: 5vh;
      text-align: center;

      .range {
        margin: 0 auto;
        width: 85vw;
        height: 3px;

        ::v-deep .el-slider__runway {
          background-color: #d1d1d1;
        }
        ::v-deep .el-slider__bar {
          background-color: #949494;
        }
        ::v-deep .el-slider__button {
          background-color: #949494;
          width: 15px;
          height: 15px;
          border: 1px solid #707070;
        }
      }

      ul {
        padding: 5px 0;
        display: flex;
        justify-content: space-between;
        li {
          font-size: 12px;
          padding: 0 7.5vw;
        }
      }
    }

    .button-foot {
      align-content: center;
      li {
        line-height: 14vh;
        width: 25px;
      }
      ul {
        padding: 0 7.5vw;
        display: flex;
        justify-content: space-between;
      }
      .iconfont {
        font-size: 20px;
      }
      .musicCtl {
        font-size: 35px;
      }
    }
  }
}
</style>
