<template>
  <div class="view">
    <div
      class="listDetail"
      :class="{ listDetailMove: store.state.lsitDetailStatus }"
    >
      <listDetail ></listDetail>
    </div>
    <div class="playList">
      <ul>
        <li
          v-for="(list, index) in data.playLists"
          :key="index"
          @click="OpenListDetail(index)"
          
        >
          <img class="listImg" :src="`${list.coverImgUrl}`" alt="" /><i
            ><span>{{ list.name }}</span
            ><span>歌单-{{ list.trackCount }}首-{{ list.nickname }}</span></i
          >
        </li>
      </ul>
    </div>
  </div>
</template>
<script setup>
import { onMounted, reactive } from "vue";
import listDetail from "./listDetail.vue";
import store from "@/store";
import bus from "@/Bus/bus";
const data = reactive({
  playLists: [],
  data:localStorage.getItem("userId")
});
const OpenListDetail = (index) => {
  bus.emit("OpenListDetail", data.playLists[index]);
  store.commit("changelistDetailStatus");
};
onMounted(() => {
    data.playLists = JSON.parse(localStorage.getItem("playLists"));
});
</script>
    <style scoped lang='scss'>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  background-color: var(--background-color);
  color: var(--text-color);
}
.listDetail {
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  transition: 0.5s;
  transform: translateX(100vh);
}
.listDetailMove {
  transform: translateX(0);
  transition: 0.5s;
}
.view {
  height: 78vh;
  overflow-y: auto;
  .playList {
    height: auto;
    ul {
      width: 100vw;
      height: auto;

      li {
        display: flex;
        align-items: center;
        height: 60px;
        .listImg {
          width: 50px;
          height: 50px;
          margin: auto 10px auto 20px;
          border-radius: 5px;
        }
        i {
          display: flex;
          flex-direction: column;
        }
        span {
          margin: auto 0;
        }
        span:nth-child(1) {
          font-size: 17px;
        }
        span:nth-child(2) {
          font-size: 12px;
        }
      }
    }
  }
}
</style>
    