import { createStore } from 'vuex'

export default createStore({
  state: {
    currentMusicListStatus: false, //展开当前歌单
    musicStatus: false, //音乐状态
    musicPlayerStatus: false, //是否在音乐播放主页面
    selectedMusic: 0,//当前音乐索引
    playPattern: 0,//播放顺序模式状态
    SearchMusicStatus: false,//搜索页面
    lsitDetailStatus: false,//歌单详情页
    musicPlayerMainStatus: false,//播放器中间样式
    playLists: [],//歌单
    currentPlayList: [],
    currentTime:0,//当前播放歌曲的时间戳,单位秒
    darkmode:false  //深色模式
  },
        // currentPlayList中的内容格式
        //{
      //   name: null,
      //   id: null,
      //   picUrl: null,
      //   totalTime: null,
      //   singer: { id: null, name: null },
      // },
  getters: {
  },
  mutations: {
    changeCurrentMusicListStatus(state) {
      state.currentMusicListStatus = !state.currentMusicListStatus
    },
    changeMusicStatus(state) {
      state.musicStatus = !state.musicStatus
    },
    changeMusicPlayerStatus(state) {
      state.musicPlayerStatus = !state.musicPlayerStatus
    },
    changeSearchMusicStatus(state) {
      state.SearchMusicStatus = !state.SearchMusicStatus
    },
    changelistDetailStatus(state) {
      state.lsitDetailStatus = !state.lsitDetailStatus
    },
    //歌单持久化操作
    localStorageSetCurrentPlayList(state) {
      localStorage.setItem('currentPlayList', JSON.stringify(state.currentPlayList))
    },
    localStorageGetCurrentPlayList(state) {
      state.currentPlayList = JSON.parse(localStorage.getItem('currentPlayList'))
    },
    changemusicPlayerMainStatus(state) {
      state.musicPlayerMainStatus = !state.musicPlayerMainStatus
    }
  },
  actions: {
  },
  modules: {
  }
})
