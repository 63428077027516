import { createRouter, createWebHashHistory } from 'vue-router';
import LoginMusic from '../views/LoginMusic.vue';
import HomeMain from '../views/HomeMain.vue';
import reCommend from '@/components/reCommend.vue';
import playList from '@/components/playList.vue';
import AboutView from '@/views/AboutView.vue';
const routes = [
  { path: '/', component: LoginMusic },
  {
    path: '/HomeMain', component: HomeMain,
    children: [
      {
        path: '/HomeMain/',
        component: reCommend
      },
      {
        path: 'reCommend',
        component: reCommend
      },
      {
        path: 'playList',
        component: playList
      }
    ]
  },
  {
    path: '/About',
    component: AboutView
  }
];
const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
});

export default router;