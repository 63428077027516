import { watch } from "vue";
import store from "@/store";
export function setupDarkModeWatcher() {
    // 设置初始值
    document.documentElement.style.setProperty('--background-color', '#ffffff');
    document.documentElement.style.setProperty('--text-color', '#000000');
    document.documentElement.style.setProperty('--music-color-top', '#c9c5c5');
    document.documentElement.style.setProperty('--music-color-bottom', '#646464');
    // 监听 darkmode 状态变化
    watch(
      () => store.state.darkmode,
      (newval) => {
        document.documentElement.style.setProperty(
          '--background-color',
          newval ? '#2D2D37' : '#ffffff'
        );
        document.documentElement.style.setProperty(
          '--text-color',
          newval ? '#ffffff' : '#000000'
        );
        document.documentElement.style.setProperty(
            '--music-color-top',
            newval ? '#646464' : '#c9c5c5'
          );
          document.documentElement.style.setProperty(
            '--music-color-bottom',
            newval ? '#c9c5c5' : '#646464'
          );
      }
    );
  }