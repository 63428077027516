import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/iconfont/iconfont.css'
import '../node_modules/bootstrap/dist/css/bootstrap.css'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import { setupDarkModeWatcher } from './hook/setupDarkModeWatcher';
const app = createApp(App);
setupDarkModeWatcher()
app.use(router);
app.use(ElementPlus);
app.use(store);
app.mount('#app');
