import service from "./http";
export function getcode(phone){  //获取验证码
    return service({
        method:"GET",
        url:`/captcha/sent?phone=${phone}`
    })
}
export function verifyCode(data){ //验证验证码
    return service({
        method:"GET",
        url:`/captcha/verify?phone=${data.phone}&captcha=${data.code}`

    })
}
export function passwordlogin(data){ //账号密码登录
return service({
    method:"POST",
    url:`/login/cellphone?phone=${data.phone}&password=${data.password}`
})
}
export function passwordloginMail(data){ //账号密码登录（邮箱）
    return service({
        method:"POST",
        url:`/login?email=${data.mail}&password=${data.password}`
    })
    }
export function logOut(){ //退出登录
    localStorage.clear("token");
    localStorage.clear("userId");
    return service({
        method:"GET",
        url:`/logout`
    })
}
export function logStatus(){ //登录状态
    return service({
        method:"GET",
        url:`/login/status`
    })
}
export function refreshStatus(){ //登录状态
    return service({
        method:"GET",
        url:`/login/refresh`
    })
}
