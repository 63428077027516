import service from "./http";
export function getDetail(data) { //获取用户详情
    return service({
        method: "GET",
        url: `/user/detail?uid=${data.uid}`
    })
}
export function subCount() {//获取用户信息 , 歌单，收藏，mv, dj 数量
    return service({
        method: "GET",
        url: `/user/subcount`
    })
}
export function playLists(data) {
    return service({
        method: "GET",
        url: `/user/playlist?uid=${data.userId}`
    })
}
export function playListDetail(data) {
    return service({
        method: "GET",
        url: `/playlist/detail?id=${data.id}` //获得歌单后直接将歌曲id赋值https://music.163.com/song/media/outer/url?id=id.mp3 就可以播放了
    })
}
export function search(data) {  //搜索歌曲
    return service({
        method: "GET",
        url: `/search?keywords=${data.keywords}`
    })
}
export function songDetail(data) { //获取单曲信息
    return service({
        method: "GET",
        url: `/song/detail?ids=${data.id}`
    })
}
export function recommendList() {//推荐歌单
    return service({
        method: "GET",
        url: `/recommend/resource`
    })
}
export function getlyric(data) {  //获取歌词
    return service({
        method: "GET",
        url: `/lyric?id=${data.id}`
    })
}
export function getTopList() {  //获取排行榜
    return service({
        method: "GET",
        url: `/toplist`
    })
}