<template>
  <div>
    <div class="head">
      <img class="head-img" src="../assets/R-C.png" alt="" />
    </div>
    <div class="main">
      <div class="alert alert-danger" role="alert" v-if="!data.loginCode">
        {{ data.message }}
      </div>
      <input
        class="main-input"
        type="text"
        v-model="data.phonedata.mail"
        placeholder="邮箱···"
        v-if="!data.passcheck"
      />
      <input
        class="main-input"
        type="text"
        v-model="data.phonedata.phone"
        placeholder="账号···"
        v-if="data.passcheck"
      />
      <input
        class="main-input"
        type="password"
        v-model="data.phonedata.password"
        placeholder="密码···"
      />
      <i class="main-i" @click="login">登录</i>
    </div>
    <div class="foot">
      <i v-if="!data.passcheck" @click="changePasscheck">账号密码登录</i>
      <i v-if="data.passcheck" @click="changePasscheck">邮箱密码登录</i>
    </div>
  </div>
</template>
  <script setup>
import { reactive } from "vue";
import { useRouter } from "vue-router";
import { passwordlogin, passwordloginMail } from "../request/login";
import { getMusic } from "@/hook/getlist";
// import { playListDetail } from "@/request/getInfor";
// import store from "@/store";
const data = reactive({
  phonedata: {
    phone: "",
    password: "",
    mail: "",
  },
  passcheck: "true",
  loginCode: "true",
  message: "",
  id: 12457925853, //歌单ID测试使用
});
const router = useRouter();
async function  handleMusicAndNavigation(profile) {
          await  getMusic(profile)
        router.push("/HomeMain");
        }
const login = () => {
  const loginMethod = data.passcheck ? passwordlogin : passwordloginMail;
  loginMethod(data.phonedata)
    .then((res) => {
      if (res.data.code == 200) {
        //存到浏览器
        // localStorage.setItem("token", res.data.token);
        localStorage.setItem("userId", res.data.profile.userId);
        // playListDetail(data) //获取歌单
        // .then((res) => {
        //   store.state.currentPlayList = [];
        //   store.state.currentPlayList = res.data.playlist.tracks.map(
        //     (track) => ({
        //       name: track.name,
        //       id: track.id,
        //       picUrl: track.al.picUrl,
        //       totalTime: track.dt,
        //       singer: { id: track.ar[0].id, name: track.ar[0].name },
        //     })
        //   );
        //   console.log(store.state.currentPlayList )
        // })
        // .finally(() => {store.commit("localStorageCurrentPlayList")});
       
        handleMusicAndNavigation(res.data.profile)
      } else {
        data.loginCode = false;
        data.message = res.data.message;
      }
    })
};
const changePasscheck = () => {
  data.passcheck = !data.passcheck;
  data.loginCode = true;
};
</script>
  <style scoped lang='scss'>
.head {
  width: 100vw;
  height: 40vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  .head-img {
    width: 80px;
    height: 80px;
  }
}

.main {
  position: relative;
  width: 100vw;
  height: 40vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .alert {
    position: fixed;
    top: 40vh;
    width: 70vw;
    font-size: 12px;
  }
  .main-input {
    width: 70vw;
    height: 7vh;
    margin-bottom: 2vh;
    border: 1px solid #333;
    border-radius: 10px;
  }

  .main-i {
    width: 70vw;
    height: 5vh;
    background-color: #fe3e37;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid;
    border-radius: 5vh;
  }
}
.foot {
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  color: rgb(0, 27, 116);
}
</style>
  