import { playLists, playListDetail } from "@/request/getInfor";
import store from "@/store";
export async function getMusic(data) {
    try {
        const res = await playLists(data);
        store.state.playLists = res.data.playlist.map((list) => ({
            nickname: list.creator.nickname,
            trackCount: list.trackCount,
            coverImgUrl: list.coverImgUrl,
            name: list.name,
            id: list.id,
            avatarUrl: list.creator.avatarUrl
        }));
        // if (res.data.playlist && res.data.playlist.length > 0) {  //这样可以解决第一个歌单不存在歌曲的问题，但是api访问有时间间隔，导致只能放弃
        //     for (let i = 0; i < res.data.playlist.length; i++) {
        //         const detailRes = await playListDetail(res.data.playlist[0]);
        //         store.state.currentPlayList = detailRes.data.playlist.tracks.map(
        //             (track) => ({
        //                 name: track.name,
        //                 id: track.id,
        //                 picUrl: track.al.picUrl,
        //                 totalTime: track.dt,
        //                 singer: { id: track.ar[0].id, name: track.ar[0].name },
        //             })
        //         );
        //         if (store.state.currentPlayList.length > 0)
        //             console.log(store.state.currentPlayList);
        //         store.commit("localStorageSetCurrentPlayList");
        //         break
        //     }
        // } else {
        //     console.error("没有歌单！！！");
        // }
        if (res.data.playlist && res.data.playlist.length > 2) {
            const detailRes = await playListDetail(res.data.playlist[1]);
            store.state.currentPlayList = detailRes.data.playlist.tracks.map(
                (track) => ({
                    name: track.name,
                    id: track.id,
                    picUrl: track.al.picUrl,
                    totalTime: track.dt,
                    singer: { id: track.ar[0].id, name: track.ar[0].name },
                })
            );
            store.commit("localStorageSetCurrentPlayList");
        } else {
            console.error("没有歌单！！！");
        }
    } catch (error) {
        console.error(error);
    } finally {
        localStorage.setItem("playLists", JSON.stringify(store.state.playLists));
    }
}
