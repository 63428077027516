<template>
  <div>
    <div class="head">
      <i class="iconfont icon-fanhui" @click="NavigateToSetting"></i
      ><i>关于网易云音乐移动Web</i>
    </div>
    <div class="main">
      <div class="img-state">
        <img class="img-main" src="../assets/R-C.png" alt="" />
      </div>
      <ul class="main-ul">
        <li><i>当前版本</i><i>1.0.0</i></li>
        <li><i>前端</i><i>Vue3</i></li>
        <li><i>后端</i><i>NeteaseCloudMusicApi</i></li>
      </ul>
    </div>
    <div class="foot">
      <i>没备案，学习使用</i>
    </div>
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";
const router = useRouter();
const NavigateToSetting = () => {
  router.push("/HomeMain");
};
</script>
<style scoped lang='scss'>
* {
  margin: 0;
  padding: 0;
}
.head {
  height: 5vh;
  text-align: center;
  position: relative;
  line-height: 5vh;
  .icon-fanhui {
    display: inline-block;
    position: absolute;
    left: 10px;
    font-size: 20px;
  }
}
.main {
  height: 54vh;
  .img-state {
    background-color: #ffffff;
    width: 100vw;
    height: 30vh;
    position: relative;
  }
  .img-main {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 100px;
    width: 100px;
  }
  .main-ul {
    height: 24vh;
    li {
      height: 8vh;
      border-top: 1px solid #ffffff;
      border-bottom: 1px solid #ffffff;
      line-height: 8vh;
      margin-left: 10px;
    }
    i:nth-child(2) {
      float: right;
      margin-right: 10px;
    }
  }
}
.foot {
  height: 45vh;
  width: 100vw;
  background-color: #ffffff;
  i {
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 13px;
  }
}
</style>
